// Import dependencies
import React from "react";
import { Link, navigate } from "gatsby";
import { Row, Col } from "antd";

// Import components
import Layout from "../../layouts/layout";
import SEO from "../seo/seo";
import HeaderSeparator from "../separators/headerSeparator";
import SectionSeparator from "../separators/sectionSeparator";
import { PageContainer, TitleText } from "../../styled-components/UILibrary";
import TitleSection from "../sections/titleSection"
import CategoryDrawer from "../cateogry-drawer/categoty-drawer";
import SearchBar from "../search-bar/searchBar";
import IndividualPromo from "../../assets/icons/individual_promo.svg";
import BusinessPromo from "../../assets/icons/business_promo.svg";

// Import styles
import "./signed-out.css";
import "../../pages/recycle.css";

// Ger firebase API
import { getFirebase } from "../../api/firebase";

// Import redux
import { connect } from "react-redux";
import { Constants } from "../../constants/Constants";

// Import img
import CasualImg from "../../assets/images/pricing/chat.svg";
import LifestyleImg from "../../assets/images/pricing/lifestyle.svg";
import BusinessImg from "../../assets/images/pricing/city.svg";
import DropDowns from "../faqs/dropDowns";
import { pricingSection } from "../../data/faqs";
import { DEPLOY_TYPE, STRIPE_CONSTANT } from "../../constants/stripe";

import BusinessUseCase1 from "../../assets/images/business_use_case1.png";
import BusinessUseCase2 from "../../assets/images/business_use_case2.png";
import BusinessUseCase3 from "../../assets/images/business_use_case3.png";
import BusinessUseCase4 from "../../assets/images/business_use_case4.png";
import BusinessUseCase5 from "../../assets/images/business_use_case5.png";
import BusinessUseCase6 from "../../assets/images/business_use_case6.png";

import PricingMoreReason1 from "../../assets/images/pricing_more_reason1.png";
import PricingMoreReason2 from "../../assets/images/pricing_more_reason2.png";
import PricingMoreReason3 from "../../assets/images/pricing_more_reason3.png";
import PricingMoreReason4 from "../../assets/images/pricing_more_reason4.png";
import PricingMoreReason5 from "../../assets/images/pricing_more_reason5.png";

/* Signed-out Layout for Recycle More page */
class SignedOutLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      benefitsShow1: false,
      benefitsShow2: false,
      quoteMonthly: false,
      benefitsToggle: true,
      benefitsPremium1 : {
        title: "Premium",
        description: "Earn more and get paid faster for recycling your pre-owned things.",
        priceMonth: "8.97",
        priceMonthDescription: "$26.91 billed every 3-months",
        priceYear: "3.97",
        priceYearDescription: "$47.64 billed annually",
        discount: "56"
      },
      benefitsPremium2 : {
        title: "SME Premium",
        description: "Sell select waste byproducts, equipment, and deadstock",
        priceMonth: "49",
        priceMonthDescription: "$147 billed every 3-months",
        priceYear: "29",
        priceYearDescription: "$348 billed annually",
        discount: "41"
      },
      subscription: null,
      isHeroMember: false,
      membership_code: null,
      loaded: false

    };
    this.onClickShowBenefits1 = this.onClickShowBenefits1.bind(this);
    this.onClickShowBenefits2 = this.onClickShowBenefits2.bind(this);
    this.joinMember = this.joinMember.bind(this);
    this.isBrowser = this.isBrowser.bind(this);
  }

  isBrowser = () => typeof window !== "undefined";

  componentDidMount() {

    const app = import("firebase/app");
    const firestore = import("firebase/firestore");

    Promise.all([app, firestore]).then(([firebase]) => {

      getFirebase(firebase)
        .firestore().collection("subscription").get().then((querySnapshot) => {
          let subscriptionArray = [];
          querySnapshot.forEach((doc) => {
            subscriptionArray.push(doc.data());
          });
          this.setState({subscription: subscriptionArray});
      });

      if (this.props.userToken) {

        getFirebase(firebase)
        .firestore()
        .collection("users")
        .doc(this.props.userToken.uid)
        .get()
        .then(doc => {
          const membership_code = {
            SME: {
                yearly: doc.data().membership_info.membership_code_ys,
                quarterly: doc.data().membership_info.membership_code_qs,
            },
            Regular: {
                yearly: doc.data().membership_info.membership_code_yr,
                quarterly: doc.data().membership_info.membership_code_qr
            }
          };
          const isHeroMember = doc.data().membership_info.is_member;
          this.setState({
            isHeroMember:  isHeroMember,
            membership_code: membership_code,
            loaded: true
          })
        });

      } else {
        this.setState({
          loaded: true
        })
      }
    });

  }

  onClickShowBenefits1() {
    if (this.state.benefitsShow1 == true) {
      this.setState({benefitsShow1: false});
    } else {
      this.setState({benefitsShow1: true});
    }
  }

  componentDidUpdate() {
    console.log("benefitsShow1" + this.state.benefitsShow1);
    console.log("benefitsShow2" + this.state.benefitsShow2);
    console.log("quoteMonthly" + this.state.quoteMonthly);
    console.log("benefitsToggle" + this.state.benefitsToggle);
  }

  onClickShowBenefits2() {
    if (this.state.benefitsShow2 == true) {
      this.setState({benefitsShow2: false});
    } else {
      this.setState({benefitsShow2: true});
    }
      console.log(this.state.benefitsShow2 + " 2");
  }

  toggleTabs(type) {
    this.setState({
      benefitsToggle: type
    });
  }

  joinMember() {
    if ( this.props.userToken && !this.state.isHeroMember) {
      let planIsYearly = !this.state.quoteMonthly ? "yearly" : "quarterly";
      let planIsSME = !this.state.benefitsToggle ? "SME" : "Regular";

      const app = import("firebase/app");
      const fuctions = import("firebase/functions")

      Promise.all([app, fuctions]).then(async ([firebase]) => {
        
        var stripeCheckoutMember = getFirebase(firebase)
        .functions()
        .httpsCallable("stripeCheckoutMember");

        stripeCheckoutMember({
          priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["MEMBER_PLAN"][planIsSME][planIsYearly],
          websiteUrl: "https://quirky-poitras-f877b7.netlify.app",
          successParams: "?key=" + this.state.membership_code[planIsSME][planIsYearly] + "&usertoken=" + this.props.userToken.uid,
          isTest: false
        })
        .then((data) => {
          if (this.isBrowser()) {
            window.location.replace(data.data.url);
          }          
        });   

      });

    }
    if ( !this.props.userToken ) {

      navigate("/register", {
        state: {
          isPaid: true,
          planIsSME: !this.state.benefitsToggle,
          planIsYearly: !this.state.quoteMonthly
        }
      })

    } else if ( this.state.isHeroMember ) {
      navigate("/account")
    }
  }

  async redirectToCheckout() {
  return 0}

  async redirectToBusinessCheckout() {
  return 0}

  render() {
    const { userToken, seoTitle = "Recycle More" } = this.props;
    if (this.state.loaded) {

      return (
        <Layout>
          <CategoryDrawer/>
          <SEO
            title={seoTitle}
            description="Get paid to recycle your electronic gadgets, appliances, and office equipment."
          />
  
          <SearchBar/>
          {/* Page content */}
          <PageContainer className="quote_page_container">
                <div className="pricing_page_title">
                  <h1>Who said being green doesn’t pay?</h1>
                  <p>Earn up to 30% more for your recyclables, and enjoy free pickups, same-day payment,<br/> members-only rewards, and more for just $3.97/month</p>
  
                  <div className="tabs_header">
                    <div class={this.state.benefitsToggle ? "tab_btn active" : "tab_btn"} onClick={(e) => this.toggleTabs(true)}>Individual Plans</div>
                    <div class={this.state.benefitsToggle ? "tab_btn" : "tab_btn active"} onClick={(e) => this.toggleTabs(false)}>SME Plans</div>
                  </div>
  
                  <div className="pricing_division"></div>
                  
                </div>
  
  
                <div className="pricing_switch_container">
                  <label>
                    <span onClick={() => {this.setState({quoteMonthly:true})}}>
                      Quarterly
                    </span>
                  </label>
                  <div className="pricing_switch" onClick={() => this.setState({quoteMonthly: !this.state.quoteMonthly})} >
                    <div className="pricing_switch_btn" style={!this.state.quoteMonthly ? {left: 40} : {}}></div>
                  </div>
                    <label>
                      <span onClick={() => {this.setState({quoteMonthly:false})}}>
                        Yearly
                        { !this.state.quoteMonthly &&
                          <span className="pricing_label_light">SAVE {
                            this.state.benefitsToggle ? this.state.benefitsPremium1.discount : this.state.benefitsPremium2.discount
                          }%</span>
                        }
                      </span>
                    </label>
                </div>
  
            <div className="pricing_table_container">
              <div className="pricing_table_shadow"></div>
              <div className="pricing_table_shadow_bottom"></div>
              <div className="pricing_table">
                <div className="pricing_table_item">
                  <div className="pricing_table_head">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.0561 10.75L17.2998 1.67505C16.7561 -0.106201 14.2436 -0.106201 13.7186 1.67505L10.9436 10.75H2.59983C0.781081 10.75 0.0310811 13.0938 1.51233 14.1438L8.33733 19.0188L5.65608 27.6625C5.11233 29.4063 7.13733 30.8125 8.58108 29.7063L15.4998 24.4563L22.4186 29.725C23.8623 30.8313 25.8873 29.425 25.3436 27.6813L22.6623 19.0375L29.4873 14.1625C30.9686 13.0938 30.2186 10.7688 28.3998 10.7688H20.0561V10.75Z" fill="#FCCB64"/>
                    </svg>
                    <span>Hero{!this.state.benefitsToggle && " SME"}</span>
                  </div>
                  <p>What you get</p>
                  <div className="pricing_table_list">
                    <div className="pricing_table_list_item">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="white"/>
                      </svg>
                      <p>Earn <span>up to 30%</span> more for recycling electronics, books, clothes, and cookware</p>
                    </div>
                    <div className="pricing_table_list_item">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="white"/>
                      </svg>
                      <p><span>4x FREE</span> collections per year</p>
                    </div>
                    <div className="pricing_table_list_item">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="white"/>
                      </svg>
                      <p>Payment issued within <span>24-hours</span> after we collect your items</p>
                    </div>
                  </div>
                  <div className="price_per_month">
                    <span>${this.state.benefitsToggle ? 
                    this.state.quoteMonthly ? this.state.benefitsPremium1.priceMonth : this.state.benefitsPremium1.priceYear : 
                    this.state.quoteMonthly ? this.state.benefitsPremium2.priceMonth : this.state.benefitsPremium2.priceYear}
                    </span>
                    <span>/month</span>
                  </div>
                  <span className="price_per_month_sub">{this.state.benefitsToggle ? 
                    this.state.quoteMonthly ? this.state.benefitsPremium1.priceMonthDescription : this.state.benefitsPremium1.priceYearDescription : 
                    this.state.quoteMonthly ? this.state.benefitsPremium2.priceMonthDescription : this.state.benefitsPremium2.priceYearDescription}
                  </span>
                  <div className="price_btn_join" onClick={() => this.joinMember()}>Join</div>
                </div>
  
                <div className="pricing_table_item">
                  <div className="pricing_table_head">
                    <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.2498 29.0501C17.9831 30.2001 16.0331 30.2001 14.7664 29.0335L14.5831 28.8668C5.8331 20.9501 0.116434 15.7668 0.3331 9.30011C0.4331 6.46678 1.8831 3.75011 4.2331 2.15011C8.6331 -0.849887 14.0664 0.550114 16.9998 3.98345C19.9331 0.550114 25.3664 -0.866553 29.7664 2.15011C32.1164 3.75011 33.5664 6.46678 33.6664 9.30011C33.8998 15.7668 28.1664 20.9501 19.4164 28.9001L19.2498 29.0501Z" fill="#D30000"/>
                    </svg>
                    <span>Guardian{!this.state.benefitsToggle && " SME"}</span>
                  </div>
                  <p>What you get</p>
                  <div className="pricing_table_list">
                    <div className="pricing_table_list_item">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#000"/>
                      </svg>
                      <p>Get paid to recycle your electronics, books, clothes, and cookware</p>
                    </div>
                    <div className="pricing_table_list_item">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#000"/>
                      </svg>
                      <p>Schedule door-to-door collection for your items <span>on-demand</span></p>
                    </div>
                    <div className="pricing_table_list_item">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#000"/>
                      </svg>
                      <p>Payment issued within <span>3-days</span> after we collect your items</p>
                    </div>
                  </div>
                  <div className="price_free">
                    Free forever
                  </div>
                  <div className="price_btn_join" onClick={() => navigate("/register")}>Register in 30-seconds</div>
                </div>
              </div>
            </div>
          { this.state.benefitsToggle ?
          <>
          <div className="columns_with_icons">
            <h1>More reasons to join</h1>
            <div className="columns_with_icons_container">
              <div className="columns_with_icons_flex">
                <div className="columns_with_icons_item">
                  <img src={PricingMoreReason3} />
                  <h3>Analytics</h3>
                  <p>Track the frequency, brands, and quantity of material your business recycled over time</p>
                </div>
                <div className="columns_with_icons_item">
                  <img src={PricingMoreReason4} />
                  <h3>Simplicity</h3>
                  <p>Our ecommerce-like experience for recycling makes it easy to do the right thing</p>
                </div>
                <div className="columns_with_icons_item">
                  <img src={PricingMoreReason5} />
                  <h3>Data protection</h3>
                  <p>We’re serious about how data on electronic devices is handled <a target="_blank" href="https://help.reinin.sg/" className="columns_with_icons_link" style={{display: "block"}}>Learn more</a></p>
                </div>
              </div>
            </div>
          </div>

          <div className="form_page_container business_use_case_container">
          <div className="case_study_container">
              <h1>Use cases</h1>
              <div className="case_study_item business_use_case_item">
                <div className="business_use_case_img">
                  <img src={BusinessUseCase4} style={{height: "auto", width: 559, left: -80, top: -37}} />
                </div>
                <div className="case_study_item_info">
                  <h2>Spring cleaning</h2>
                  <p>Members can book free pickups, which means you get  more cash for the recyclable items you discard after CNY spring cleaning or during house moving!</p>
                </div>
              </div>
              <div className="case_study_item business_use_case_item">
              <div className="business_use_case_img">
                <img src={BusinessUseCase5} style={{height: "auto", width: 559, left: -80, top: -37}} className="business_use_case_img_special"/>
              </div>
                <div className="case_study_item_info">
                  <h2>Lifestyle transition</h2>
                  <p>Use exclusive member discounts along with your earnings from recycling for massive savings on a wide range of eco-friendly essentials on the <Link to="/shop" className="columns_with_icons_link">Ree store</Link></p>
                </div>
              </div>
              <div className="case_study_item business_use_case_item">
              <div className="business_use_case_img">
                <img src={BusinessUseCase6} style={{height: "auto", width: 559, left: -80, top: -37}} />
              </div>
                <div className="case_study_item_info">
                  <h2>Inspire change</h2>
                  <p>Experience pride and accomplishment from driving change in your community, and know that you’re playing a part in safeguarding the environment.</p>
                </div>
              </div>
            </div>
          </div>
        </>
          : <>
            <div className="columns_with_icons">
              <h1>More reasons to join</h1>
              <div className="columns_with_icons_container">
                <div className="columns_with_icons_flex">
                  <div className="columns_with_icons_item">
                    <img src={PricingMoreReason3} />
                    <h3>Analytics</h3>
                    <p>Track the frequency, brands, and quantity of material your business recycled over time</p>
                  </div>
                  <div className="columns_with_icons_item">
                    <img src={PricingMoreReason2} />
                    <h3>On-site bins</h3>
                    <p>Request a whitelabel collection bin — we’ll empty it for you as needed, and pay you for its contents</p>
                  </div>
                  <div className="columns_with_icons_item">
                    <img src={PricingMoreReason1} />
                    <h3>Itemized receipts</h3>
                    <p>Get marketable insights into the type of items your company receives and recycles</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="form_page_container business_use_case_container">
            <div className="case_study_container">
                <h1>Business use cases</h1>
                <div className="case_study_item business_use_case_item">
                  <div className="business_use_case_img">
                    <img src={BusinessUseCase1} style={{height: "auto", width: 559, left: -80, top: -37}} />
                  </div>
                  <div className="case_study_item_info">
                    <h2>Engagement programs</h2>
                    <p>Easily implement trade-up programs for your products, or allow your customers to trade-in recyclable items for access to events or discounts on your products.</p>
                  </div>
                </div>
                <div className="case_study_item business_use_case_item">
                <div className="business_use_case_img">
                  <img src={BusinessUseCase2} style={{height: "auto", width: 559, left: -80, top: -37}} />
                </div>
                  <div className="case_study_item_info">
                    <h2>Inventory management</h2>
                    <p>Unlock the value trapped in your deadstock by selling those items to Reinin, and let us handle the process of recycling them.</p>
                  </div>
                </div>
                <div className="case_study_item business_use_case_item">
                <div className="business_use_case_img">
                  <img src={BusinessUseCase3} style={{height: "auto", width: 559, left: -80, top: -37}} />
                </div>
                  <div className="case_study_item_info">
                    <h2>Got questions?</h2>
                    <p>Visit our FAQs or contact us to discuss how we can support the sustainability goals of your business.</p>
                    <div className="how_it_work_links">
                      <span onClick={() => navigate("https://help.reinin.sg/")}>
                        See FAQs
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                      <span onClick={() => navigate("https://help.reinin.sg/")}>
                        Contact sales
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
            }

          </PageContainer>

        </Layout>
      );
      
    } else {

      return (
        <Layout>
          <CategoryDrawer/>
          <SEO
            title={seoTitle}
            description="Get paid to recycle your electronic gadgets, appliances, and office equipment."
          />
          <SearchBar/>
        </Layout>
      );

    }
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  userToken: state.user.userToken
});

export default connect(mapStateToProps)(SignedOutLayout);
